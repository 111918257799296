import React from "react"

// Libraries
import styled from "styled-components"

// Components
import Container from "components/container"
import DownloadsSection from "components/downloads-sections"

// Training Materials
import RespiEmailCommunication from "assets/downloads/visby-university/respiratory-health-test/VM-Respi-Internal-Comm-Template.pdf"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px;
`

const BillingSection = props => {
	return (
		<DownloadsSection
			id="respi-billing"
			viewAllowed={props.viewAllowed}
			data-filter="respiratory" data-filter-type="devices" data-section="billing"
		>
			<StyledContainer>
				<div className="block__content">
					<h3>Coding and Operational Resources</h3>
				</div>
				<div className="block">
					<div className="block__material">
						<ul className="downloadsList">
							<li>
								<a href="https://www.codemap.com/visby/?state=00000&locality=null" target="_blank" rel="noopener noreferrer">CPT Billing-Code Map</a>
							</li>
							<li>
								<a href={RespiEmailCommunication} target="_blank" rel="noopener noreferrer">Internal Communication Announcement-Visby Respiratory Health Test</a>
							</li>
						</ul>
					</div>
				</div>
			</StyledContainer>
		</DownloadsSection>
	)
}

export default BillingSection
